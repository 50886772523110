import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    if (this.invalidField) {
      this.invalidField.focus()
      this.scrollIntoField()
    }
  }

  get invalidField() {
    return document.querySelector(this.selectorValue)
  }

  scrollIntoField() {
    let elementRect = this.invalidField.getBoundingClientRect();
    let absoluteElementTop = elementRect.top + window.pageYOffset;
    let middle = absoluteElementTop - (window.innerHeight / 2);
    window.scrollTo(0, middle);
  }
}
