// Generated by CoffeeScript 2.7.0
(function() {
  var $, SubmitOnKeyup, submitFormEvent;

  $ = jQuery;

  submitFormEvent = new Event('submit', {
    bubbles: true,
    cancelable: true
  });

  $(document).on("change", '[data-behavior~=submit-on-change]', function(event) {
    var form, name, oldAction, oldMethod, resetForm, target;
    target = event.currentTarget;
    form = target.form;
    name = target.dataset.submitOnChangeName;
    resetForm = target.dataset.submitOnChangeResetForm;
    if (name) {
      $(form).append(`<input type='hidden' name='${name}' value='true'>`);
    }
    $(form).append("<input type='hidden' name='submit_on_change' value='true'>");
    oldAction = form.action;
    oldMethod = form.method;
    if (target.dataset.formaction) {
      form.action = target.dataset.formaction;
    }
    if (target.dataset.method) {
      form.method = target.dataset.method;
    }
    if (form.dataset.remote) {
      form.dispatchEvent(submitFormEvent);
    } else {
      form.submit();
    }
    form.action = oldAction;
    form.method = oldMethod;
    if (resetForm) {
      return form.reset();
    }
  });

  SubmitOnKeyup = class SubmitOnKeyup {
    static setup() {
      return $(document).on("keyup", '[data-behavior~=submit-on-keyup]', this.onKeyup);
    }

    static instance(element) {
      return element.data("submit-on-keyup") || new SubmitOnKeyup(element);
    }

    static onKeyup(event) {
      return SubmitOnKeyup.instance($(event.currentTarget)).onKeyup();
    }

    constructor(element) {
      this.ajaxBefore = this.ajaxBefore.bind(this);
      this.ajaxComplete = this.ajaxComplete.bind(this);
      this.submit = this.submit.bind(this);
      this.element = element;
      this.element.data("submit-on-keyup", this);
      this.addObservers();
    }

    addObservers() {
      this.form().on("ajax:beforeSend", this.ajaxBefore);
      return this.form().on("ajax:complete", this.ajaxComplete);
    }

    ajaxBefore(event) {
      return this.xhr = event.detail[0];
    }

    ajaxComplete(event) {
      return this.xhr = null;
    }

    onKeyup() {
      if (this.xhr) {
        this.xhr.abort();
      }
      clearTimeout(this.timer);
      return this.timer = setTimeout(this.submit, this.delay());
    }

    submit() {
      if (this.lastValue !== this.currentValue()) {
        this.lastValue = this.currentValue();
        return this.form()[0].dispatchEvent(submitFormEvent);
      }
    }

    form() {
      return this.element.closest("form");
    }

    delay() {
      return parseInt(this.element.data("delay") || 0);
    }

    currentValue() {
      return this.element.val();
    }

  };

  SubmitOnKeyup.setup();

}).call(this);
