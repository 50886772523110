// Generated by CoffeeScript 2.7.0
(function() {
  window.LocationSelect = (function() {
    class LocationSelect {
      static attach() {
        return $("[data-behavior~=location-select]").each(LocationSelect.attachOne);
      }

      static attachOne(index, element) {
        if (!$(element).data('location-select')) {
          return $(element).data('location-select', new LocationSelect(element));
        }
      }

      constructor(element) {
        this.isSubmitOnChange = this.isSubmitOnChange.bind(this);
        this.fillInAddress = this.fillInAddress.bind(this);
        this.clearIfNotSelected = this.clearIfNotSelected.bind(this);
        this.setChosePlace = this.setChosePlace.bind(this);
        this.setNameFieldIfSubmitOnChange = this.setNameFieldIfSubmitOnChange.bind(this);
        this.preventSubmit = this.preventSubmit.bind(this);
        this.element = $(element);
        this.setChosePlace();
        GooglePlacesAPI.load().then(() => {
          return this.initAutocomplete();
        });
      }

      initAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(this.element[0], {
          types: [this.element.data('types')]
        });
        this.autocomplete.addListener('place_changed', this.fillInAddress);
        this.element[0].addEventListener('blur', this.clearIfNotSelected);
        return this.element.on("keydown", this.preventSubmit);
      }

      dataTarget() {
        return $(this.element.data("target"));
      }

      placeIdTarget() {
        return $(this.element.data("target-place-id"));
      }

      locationNameField() {
        return $(this.element.data("location-name-field"));
      }

      isSubmitOnChange() {
        return this.element[0].dataset.behavior.split(" ").includes("submit-on-location-change");
      }

      fillInAddress() {
        var place;
        place = this.autocomplete.getPlace();
        this.dataTarget().val(JSON.stringify(place));
        this.placeIdTarget().val(place.place_id);
        if (typeof place.place_id !== 'undefined') {
          this.setChosePlace();
          return this.setNameFieldIfSubmitOnChange(this.chosePlace);
        }
      }

      clearIfNotSelected(event) {
        if (this.element[0].value !== this.chosePlace && !event.rangeParent.parentElement.closest(".pac-item")) {
          this.element.val("");
          this.placeIdTarget().val(null);
          this.dataTarget().val(null);
          return this.setNameFieldIfSubmitOnChange(null);
        }
      }

      setChosePlace() {
        return this.chosePlace = this.element[0].value;
      }

      setNameFieldIfSubmitOnChange(value) {
        if (this.chosePlace && this.isSubmitOnChange()) {
          this.locationNameField().val(value);
          return this.locationNameField().change();
        }
      }

      preventSubmit() {
        if (event.key === 'Enter') {
          return event.preventDefault();
        }
      }

    };

    LocationSelect.prototype.chosePlace = "";

    return LocationSelect;

  }).call(this);

  Dom.ready(LocationSelect.attach);

}).call(this);
