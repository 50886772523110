// Generated by CoffeeScript 2.7.0
(function() {
  window.GooglePlacesAPI = (function() {
    class GooglePlacesAPI {
      constructor() {
        this.onLoad = this.onLoad.bind(this);
      }

      static load() {
        return new Promise((resolve, reject) => {
          var api;
          if (this.loaded) {
            resolve();
          } else {
            window.addEventListener("googlePlacesAPI.ready", resolve);
          }
          if (!this.loading) {
            api = new GooglePlacesAPI();
            api.load();
            return this.loading = true;
          }
        });
      }

      onLoad() {
        GooglePlacesAPI.loaded = true;
        return window.dispatchEvent(new CustomEvent("googlePlacesAPI.ready", {
          bubbles: true
        }));
      }

      load() {
        var js;
        window.locationSelectOnLoad = this.onLoad;
        js = document.createElement('script');
        js.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleKey()}&libraries=places&language=en&callback=locationSelectOnLoad`;
        return document.body.appendChild(js);
      }

      googleKey() {
        return $("meta[name=google-api-key]").attr("content");
      }

    };

    GooglePlacesAPI.loading = false;

    GooglePlacesAPI.loaded = false;

    return GooglePlacesAPI;

  }).call(this);

}).call(this);
