// Generated by CoffeeScript 2.7.0
(function() {
  var Spinner;

  Spinner = (function() {
    class Spinner {
      constructor() {
        this.show = this.show.bind(this);
        this.showNow = this.showNow.bind(this);
        this.hide = this.hide.bind(this);
        this.beforeAjax = this.beforeAjax.bind(this);
      }

      show() {
        if (this.timerId === null) {
          return this.timerId = setTimeout(this.showNow, 500);
        }
      }

      showNow() {
        return $(this.spinner).removeClass('hidden d-none');
      }

      hide() {
        this.clearTimeout();
        return $(this.spinner).addClass('hidden d-none');
      }

      clearTimeout() {
        clearTimeout(this.timerId);
        return this.timerId = null;
      }

      beforeAjax(event) {
        event.target.addEventListener("ajax:complete", this.hide);
        return this.show();
      }

    };

    Spinner.prototype.spinner = '.spinner';

    Spinner.prototype.timerId = null;

    return Spinner;

  }).call(this);

  window.spinner = new Spinner();

  $(document).on('ajax:before', '[data-remote]', spinner.beforeAjax);

}).call(this);
