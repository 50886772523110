/*
 * FormSubmit allow dynamicly update forms on changed values in fields.
 *
 * example:
 *
 * <select
 *   data-controller="form-submit"
 *   data-action: "form-submit#submit",
 *   data-form-submit-action-value: "/users/1/update_form",
 *   data-form-submit-method-value: "patch">
 *
 * action & method are optional.
 */

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    action: String,
    method: String,
    name: { type: String, default: "submit_via_form_submit" },
  }

  submit(event) {
    event.preventDefault();
    let submitter = this.submitter

    if (submitter.formMethod && this.formMethod) {
      this.formMethod.disabled = true
    }

    this.form.appendChild(submitter)
    submitter.click()
    this.form.removeChild(submitter)

    if (submitter.formMethod && this.formMethod) {
      this.formMethod.disabled = false
    }
  }

  get submitter() {
    let submitter = document.createElement("input")

    submitter.type = "submit"
    submitter.name = this.nameValue
    submitter.value = true
    submitter.hidden = true

    if (this.actionValue) submitter.formAction = this.actionValue
    if (this.methodValue) submitter.formMethod = this.methodValue

    return submitter
  }

  get form() {
    return this.element.form
  }

  get formMethod() {
    return this.form.querySelector("[name=_method]")
  }
}
