import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interval: Number,
    url: String
  }

  connect() {
    this.destroyed = false;
    this.schedule()
    document.addEventListener("visibilitychange", this.windowVisibilityChange.bind(this));
  }
  
  disconnect() {
    this.destroyed = true;
    if (this.timeoutId) {
      return clearTimeout(this.timeoutId);
    }  
  }

  windowVisibilityChange() {
    if (document.hidden) {
      console.log("new Stop pulling - window hidden");
      return this.disconnect();
    } else {
      console.log("new Start pulling - window visible");
      this.destroyed = false;
      return this.schedule();
    }
  };

  schedule() {
    if (!this.destroyed) { 
      return this.timeoutId = setTimeout(this.pull.bind(this), this.interval());
    }
  }
  
  pull() {
    return Rails.ajax({
      url: this.urlValue,
      type: 'GET',
      dataType: 'script',
      complete: this.schedule.bind(this)
    })
  }

  interval() {
    return this.intervalValue || 5000;
  }

}
