// Generated by CoffeeScript 2.7.0
(function() {
  window.Select2 = class Select2 {
    static setup() {
      Dom.ready(Select2.attach);
      return $(document).ajaxComplete(Select2.attach);
    }

    static attach() {
      return $("[data-behavior~=select2]").each(Select2.setupOne);
    }

    static setupOne(index, element) {
      if (!$(element).data("select2")) {
        return new Select2(element);
      }
    }

    constructor(element) {
      this.onUnselect = this.onUnselect.bind(this);
      this.onSelecting = this.onSelecting.bind(this);
      this.data = this.data.bind(this);
      this.processResults = this.processResults.bind(this);
      this.element = $(element);
      this.setWidthForElement();
      this.initializeSelect2();
      this.observeOnUnselect();
    }

    setWidthForElement() {
      return this.element.css({
        width: this.width()
      });
    }

    observeOnUnselect() {
      if (this.removeElementOnUnselect()) {
        this.element.on("select2:unselect", this.onUnselect);
      }
      return this.element.on("select2:selecting", this.onSelecting);
    }

    onUnselect(event) {
      var id;
      id = event.params.data.id.replace(/('|")/g, "\\$1");
      return this.element.find(`option[value='${id}']`).remove();
    }

    onSelecting(event) {
      if (!this.maximumSelectionSize() || this.element.select2("data").length < this.maximumSelectionSize()) {
        return true;
      } else {
        alert(`You can select maximum ${this.maximumSelectionSize()} items`);
        return false;
      }
    }

    removeElementOnUnselect() {
      return this.element.data("multiple") && this.ajaxOptions();
    }

    initializeSelect2() {
      return this.element.select2(this.options());
    }

    options() {
      var options;
      options = {};
      options.placeholder = this.placeholder();
      options.dropdownParent = this.dropdownParent();
      if (this.maximumSelectionLength()) {
        options.maximumSelectionLength = this.maximumSelectionLength();
      }
      options.theme = "bootstrap-5";
      if (this.tagsOption()) {
        options.tags = [];
      }
      if (this.ajaxOptions()) {
        options.ajax = this.ajaxOptions();
      }
      options.templateResult = this.templateResult;
      if (this.containerCssClass()) {
        options.containerCssClass = this.containerCssClass();
      }
      if (this.dropdownCssClass()) {
        options.dropdownCssClass = this.dropdownClssClass();
      }
      if (this.placeholder()) {
        options.allowClear = true;
      }
      return options;
    }

    templateResult(result) {
      var text;
      text = result.label || result.text;
      if (result.element && result.element.className) {
        text = $(`<span class=\"${result.element.className}\">${text}</span>`);
      }
      return text;
    }

    ajaxOptions() {
      if (this.url()) {
        return {
          url: this.url(),
          dataType: 'json',
          cache: true,
          processResults: this.processResults,
          data: this.data
        };
      }
    }

    data(params) {
      this.query = params.term;
      this.page = params.page;
      return {
        query: this.query,
        page: this.page
      };
    }

    processResults(data, page) {
      if (this.new() && this.query && !this.includeQuery(data.results)) {
        data.results.unshift(this.newResult());
      }
      return data;
    }

    newResult() {
      return {
        text: this.query,
        id: this.query
      };
    }

    includeQuery(results) {
      var i, len, result;
      for (i = 0, len = results.length; i < len; i++) {
        result = results[i];
        if (result.text.toLowerCase() === this.query.toLowerCase()) {
          return true;
        }
      }
      return false;
    }

    new() {
      return this.element.data("new");
    }

    url() {
      return this.element.data("url");
    }

    width() {
      return this.element.data("width") || "100%";
    }

    placeholder() {
      return this.element.attr("placeholder");
    }

    dropdownParent() {
      return $(this.element.data("dropdown-parent") || "body");
    }

    tagsOption() {
      return this.element.data("tags");
    }

    maximumSelectionLength() {
      return this.element.data("maximum-selection-length");
    }

    maximumSelectionSize() {
      return this.element.data("maximum-selection-size");
    }

    containerCssClass() {
      return this.element.data("container-css-class") || ":all:";
    }

    dropdownCssClass() {
      return this.element.data("dropdown-css-class");
    }

  };

  Select2.setup();

}).call(this);
