// Generated by CoffeeScript 2.7.0
(function() {
  window.Dom = class Dom {
    static ready(callback) {
      return document.addEventListener("turbolinks:load", callback);
    }

  };

}).call(this);
