// Generated by CoffeeScript 2.7.0
(function() {
  var $, Modal;

  $ = jQuery;

  Modal = class Modal {
    constructor() {}

    container() {
      return $("#modal");
    }

    show(content) {
      this.appendTemplateIfNotExists();
      this.updateContent(content);
      if (!this.container().is(":visible")) {
        return this.container().modal('show');
      }
    }

    hide() {
      return this.container().modal('hide');
    }

    focus(selector) {
      return this.container().find(selector).focus();
    }

    appendTemplateIfNotExists() {
      if (this.container().length === 0) {
        return $('body').append(this.template());
      }
    }

    updateContent(content) {
      return this.container().html(content);
    }

    template() {
      return `<div class="modal" id="modal"></div>`;
    }

  };

  $(function() {
    return window.modal = new Modal();
  });

}).call(this);
